import React from 'react';
import axios from 'axios/index'

const API_BASE_ENDPOINT = process.env.REACT_APP_ENDPOINT || "<enter-endpoint>";
const API_GW_ID = API_BASE_ENDPOINT.match(/(https:\/\/)(.+)(?=\.execute-api)/)[2];

class Home extends React.Component {

    state = {
        message: 'Please click on allow access to your device\'s location.'
    };

    constructor(props) {
        super(props);
        this.requestLocation();
    }

    roundLatLong = (num) => {
        return Math.round((num + Number.EPSILON) * 10000) / 10000;
    }

    getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    requestLocation = async () => {
        if (!navigator.geolocation) {
            this.setState({message: <div>Error: Couldn't request geolocation from browser.</div>});
            return;
        }

        const uid = this.getParameterByName('i');
        if (!uid) {
            this.setState({message: <div>Error: Uid not present.</div>});
            return;
        }

        navigator.geolocation.getCurrentPosition(position => {
            var lat = this.roundLatLong(position.coords.latitude);
            var lon = this.roundLatLong(position.coords.longitude);
            var accuracy = Math.ceil(position.coords.accuracy);

            this.setState({message: <>
                    <div>Your location below has been sent to your NRMA Agent.</div>
                    <div>Your position is: {lat},{lon} (accurate to {accuracy} metres)</div> 
                </>
            });
            
            axios.post(`${API_BASE_ENDPOINT}/provide`, {
                uuid: uid,
                location: `${lat},${lon}`,
                accuracy: accuracy
            });

        }, error => {
            this.setState({message: <div>Error: ${error}</div>});
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    }

    render = () => {
        return (
            <div className="App">
                <div>{this.state.message}</div>
            </div>
          );
    }

}

export default Home;